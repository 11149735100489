import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import './index.css';

// Custom hook for interval
function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(() => savedCallback.current(), delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

// Game constants
const PLAYER_SPEED = 5;
const BULLET_SPEED = 7;
const ALIEN_SPEED = 2;
const ALIEN_DROP = 1;

const GameOver = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const pixelGridRef = useRef(null);

  const images = [
    "/images/Vector.svg",
    "/images/Vector2.svg",
    "/images/Vector3.svg",
    "/images/Vector4.svg",
    "/images/Vector5.svg",
    "/images/Vector6.svg",
  ];
  const gridSize = 10;
  const pixelSize = 100 / gridSize;
  const animationStepDuration = 0.6;

  useEffect(() => {
    if (!pixelGridRef.current) return;

    // Clear any existing pixels
    pixelGridRef.current.innerHTML = '';

    for (let row = 0; row < gridSize; row++) {
      for (let col = 0; col < gridSize; col++) {
        const pixel = document.createElement("div");
        pixel.className = "pixelated-image-card__pixel";
        // No need for inline styles as they're handled in CSS
        pixelGridRef.current.appendChild(pixel);
      }
    }
  }, []);


  return (
    <>
      <div className="flex items-center justify-center h-screen bg-white top-0 fixed w-[500px] left-1/2 -translate-x-1/2">

      </div>
    </>
  );
};

// Helper function
const getGameDimensions = () => {
  const width = Math.min(window.innerWidth * 0.9, 800);
  const height = Math.min(window.innerHeight * 0.8, 600);
  return { width, height };
};

// Main App component (previously SpaceInvaders)
function App() {
  console.log('SpaceInvaders component mounting');

  // Add a visible element that should show up regardless of game state
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    console.log('SpaceInvaders mounted');
    setMounted(true);
  }, []);

  const alienImages = [
    "/images/Vector.svg",
    "/images/Vector6.svg",
    "/images/Vector2.svg",
    "/images/Vector3.svg",
    "/images/Vector4.svg",
    "/images/Vector5.svg",
  ];

  const [gameDimensions, setGameDimensions] = useState(getGameDimensions());

  const [player, setPlayer] = useState({
    x: gameDimensions.width / 2 - 25,
    y: gameDimensions.height - 60,
    width: 50,
    height: 40,
  });

  const [bullets, setBullets] = useState([]);
  const [aliens, setAliens] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [direction, setDirection] = useState(1);
  const [keys, setKeys] = useState({
    left: false,
    right: false,
    space: false,
  });

  useEffect(() => {
    const initialAliens = [];
    const alienWidth = 30;
    const alienHeight = 25;
    const spacing = 50;
    const totalWidth = spacing * 8;
    const startX = (gameDimensions.width - totalWidth) / 2;

    for (let row = 0; row < 3; row++) {
      for (let col = 0; col < 8; col++) {
        const randomImage =
          alienImages[Math.floor(Math.random() * alienImages.length)];
        initialAliens.push({
          x: startX + col * spacing,
          y: row * 60 + 50,
          width: alienWidth,
          height: alienHeight,
          image: randomImage,
        });
      }
    }
    setAliens(initialAliens);
  }, [gameDimensions]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowLeft") setKeys((prev) => ({ ...prev, left: true }));
      if (e.key === "ArrowRight") setKeys((prev) => ({ ...prev, right: true }));
      if (e.key === " ") setKeys((prev) => ({ ...prev, space: true }));
    };

    const handleKeyUp = (e) => {
      if (e.key === "ArrowLeft") setKeys((prev) => ({ ...prev, left: false }));
      if (e.key === "ArrowRight")
        setKeys((prev) => ({ ...prev, right: false }));
      if (e.key === " ") setKeys((prev) => ({ ...prev, space: false }));
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useInterval(() => {
    if (gameOver) return;

    if (keys.left && player.x > 0) {
      setPlayer((prev) => ({ ...prev, x: Math.max(0, prev.x - PLAYER_SPEED) }));
    }
    if (keys.right && player.x < gameDimensions.width - player.width) {
      setPlayer((prev) => ({
        ...prev,
        x: Math.min(gameDimensions.width - player.width, prev.x + PLAYER_SPEED),
      }));
    }
  }, 16);

  useEffect(() => {
    if (keys.space) {
      const now = Date.now();
      setBullets((prev) => {
        if (prev.length === 0 || now - prev[prev.length - 1].timestamp > 250) {
          return [
            ...prev,
            {
              x: player.x + player.width / 2 - 2,
              y: player.y,
              width: 4,
              height: 10,
              timestamp: now,
            },
          ];
        }
        return prev;
      });
    }
  }, [keys.space, player.x, player.y]);

  useInterval(() => {
    if (gameOver) return;

    setBullets((prev) =>
      prev.filter((bullet) => {
        bullet.y -= BULLET_SPEED;
        return bullet.y > 0;
      })
    );

    setBullets((prev) =>
      prev.filter((bullet) => {
        let hit = false;
        setAliens((prevAliens) =>
          prevAliens.filter((alien) => {
            const collision = !(
              bullet.x > alien.x + alien.width ||
              bullet.x + bullet.width < alien.x ||
              bullet.y > alien.y + alien.height ||
              bullet.y + bullet.height < alien.y
            );

            if (collision) {
              hit = true;
              setScore((prev) => {
                const newScore = prev + 100;
                // Check if all aliens are destroyed
                if (newScore === 2400) {
                  setGameOver(true);
                }
                return newScore;
              });
            }
            return !collision;
          })
        );
        return !hit;
      })
    );
  }, 16);

  useInterval(() => {
    if (gameOver) return;

    let shouldChangeDirection = false;

    aliens.forEach((alien) => {
      const nextX = alien.x + ALIEN_SPEED * direction;
      if (nextX <= 0 || nextX + alien.width >= gameDimensions.width) {
        shouldChangeDirection = true;
      }
    });

    if (shouldChangeDirection) {
      setDirection((prev) => -prev);
      setAliens((prev) =>
        prev.map((alien) => ({
          ...alien,
          y: alien.y + ALIEN_DROP,
        }))
      );
    } else {
      setAliens((prev) =>
        prev.map((alien) => ({
          ...alien,
          x: alien.x + ALIEN_SPEED * direction,
        }))
      );
    }

    setAliens((prev) => {
      const alienReachedBottom = prev.some(
        (alien) => alien.y + alien.height >= player.y
      );
      if (alienReachedBottom) {
        setGameOver(true);
      }
      return prev;
    });
  }, 50);

  useEffect(() => {
    const handleResize = () => {
      setGameDimensions(getGameDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Add this useEffect to handle the Luma script loading
  useEffect(() => {
    // Only load the script once
    if (!document.getElementById('luma-checkout')) {
      const script = document.createElement('script');
      script.id = 'luma-checkout';
      script.src = 'https://embed.lu.ma/checkout-button.js';
      script.async = true;
      
      // Add the script to the document
      document.body.appendChild(script);
      
      // Cleanup
      return () => {
        const scriptElement = document.getElementById('luma-checkout');
        if (scriptElement) {
          document.body.removeChild(scriptElement);
        }
      };
    }
  }, []);

  console.log(score)


  return (
    <div className="relative w-full h-screen bg-white flex items-center justify-center overflow-hidden">
      {/* <div className="absolute top-0 left-0 bg-black text-white p-2 z-[9999]">
        Game Loaded: {mounted ? 'Yes' : 'No'}
      </div> */}
      <div id='register-button-container' className="absolute top-0 right-0 p-8 text-white z-[9999]">
        <a
          href="https://lu.ma/event/evt-N8MPQDxE03hf0l3"
          className="luma-checkout--button"
          data-luma-action="checkout"
          data-luma-event-id="evt-N8MPQDxE03hf0l3"
        >
          Register for Event
        </a>
      </div>
      <div
        className="relative overflow-hidden"
        style={{
          width: gameDimensions.width,
          height: gameDimensions.height,
        }}
      >
        {gameOver && <GameOver />}
        { }

        <div
          className="absolute"
          style={{
            left: player.x,
            top: player.y,
            width: player.width,
            height: player.height,
          }}
        >
          <img
            src="/images/Ship.svg"
            alt="Player Ship"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </div>

        {!gameOver && bullets.map((bullet, index) => (
          <div
            key={index}
            className="absolute bg-black "
            style={{
              left: bullet.x,
              top: bullet.y,
              width: bullet.width,
              height: bullet.height,
            }}
          />
        ))}

        {!gameOver &&
          aliens.map((alien, index) => (
            <div
              key={index}
              className="absolute"
              style={{
                left: alien.x,
                top: alien.y,
                width: alien.width,
                height: alien.height,
              }}
            >
              <img
                src={alien.image}
                alt={`Alien ${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          ))}
      </div>
      {/* <div className="absolute top-8 left-1/2  -translate-x-1/2 text-black text-xl z-[2000]">
        <img className="h-6" src="/images/wordmark.png" alt="Alien" />
      </div> */}
      <div className="absolute top-8 md:bottom-8 left-1/2  -translate-x-1/2 text-black text-xl z-[2000]">
        <img className="h-5" src="/images/date.svg" alt="date" />
      </div>
      <div className="absolute hidden md:block bottom-8 left-1/2  -translate-x-1/2 text-black text-xl z-[2000]">
        <img className="h-6" src="/images/controls.svg" alt="Alien" /> 
      </div>
      <div className="opacity-0 absolute top-8 md:bottom-8 left-1/2  -translate-x-1/2 text-black text-xl z-[2000]">
      {score}
      </div>

      {/* Mobile Controls - Only show on small screens */}
      <div className="fixed bottom-0 left-0 right-0 flex justify-between p-4 md:hidden">
        <div className="flex gap-4">
          {/* Left Button */}
          <button
            className="h-12 w-12 flex items-center justify-center active:scale-95 select-none	"
            onTouchStart={() => setKeys(prev => ({ ...prev, left: true }))}
            onTouchEnd={() => setKeys(prev => ({ ...prev, left: false }))}
            onMouseDown={() => setKeys(prev => ({ ...prev, left: true }))}
            onMouseUp={() => setKeys(prev => ({ ...prev, left: false }))}
            onMouseLeave={() => setKeys(prev => ({ ...prev, left: false }))}
          >
            <img className='w-full h-full object-contain' src="/images/left.svg" alt="Left" />
          </button>

          {/* Right Button */}
          <button
            className="h-12 w-12 flex items-center justify-center active:scale-95 select-none	"
            onTouchStart={() => setKeys(prev => ({ ...prev, right: true }))}
            onTouchEnd={() => setKeys(prev => ({ ...prev, right: false }))}
            onMouseDown={() => setKeys(prev => ({ ...prev, right: true }))}
            onMouseUp={() => setKeys(prev => ({ ...prev, right: false }))}
            onMouseLeave={() => setKeys(prev => ({ ...prev, right: false }))}
          >
            <img className='w-full h-full object-contain' src="/images/right.svg" alt="Right" />
          </button>
        </div>

        {/* Shoot Button */}
        <button
          className="h-12 w-12 flex items-center justify-center active:scale-95 select-none	"
          onTouchStart={() => setKeys(prev => ({ ...prev, space: true }))}
          onTouchEnd={() => setKeys(prev => ({ ...prev, space: false }))}
          onMouseDown={() => setKeys(prev => ({ ...prev, space: true }))}
          onMouseUp={() => setKeys(prev => ({ ...prev, space: false }))}
          onMouseLeave={() => setKeys(prev => ({ ...prev, space: false }))}
        >
          <img className='w-full h-full object-contain' src="/images/shoot.svg" alt="Shoot" />
        </button>
      </div>
      {
        gameOver && (
          <div
            id='play-button'
            onClick={() => window.location.reload()}
            className="bg-white h-52 w-1/2 max-w-[300px] fixed left-1/2 -translate-x-1/2 top-1/2 -translate-y-2/3 rounded-lg border-black border-2 p-4 z-[9998] cursor-pointer "
          >
            <div className="flex items-center justify-center h-full w-full">
              <img className='w-full h-full object-contain' src="/images/play.png" alt="Alien" />
            </div>
          </div>
        )
      }
    </div>
  );
}

export default App;
